import React, {useContext, useEffect, useState} from 'react';
import {Box, Typography, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {ErrorContext} from "./ErrorContext";
import Snackbar from '@mui/material/Snackbar';
import {styled} from '@mui/material/styles';


// Custom Snackbar color
const WarningSnackbar = styled(Snackbar)(() => ({
    '& .MuiSnackbarContent-root': {
        backgroundColor: '#FFA000',
        color: 'black',
    },
}));

export default function ErrorMessageBar() {
    const context = useContext(ErrorContext);
    const {error, message, setMessage, setError} = context;
    const sessionStorageKey = 'devMessageOpen';
    const [devMessageOpen, setDevMessageOpen] = useState(
        () => JSON.parse(sessionStorage.getItem(sessionStorageKey)) ?? true
    );

    useEffect(() => {
        sessionStorage.setItem(sessionStorageKey, JSON.stringify(devMessageOpen));
    }, [devMessageOpen]);

    if (!error && !message) {
        return null;
    }

    const handleClose = () => {
        setMessage("");
        setError("");
    };

    const handleDevMessageClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setDevMessageOpen(false);
    };

    return (
        <>
        {/*    <WarningSnackbar*/}
        {/*        style={{maxWidth: '400px'}}*/}
        {/*    open={devMessageOpen}*/}
        {/*    onClose={handleDevMessageClose}*/}
        {/*    message="Please note: Casabio is still in development and some functionality won't work. Please check in closer to our launch date: August 30th, and sign up for our launch either in person or the virtual event."*/}
        {/*    action={*/}
        {/*        <>*/}
        {/*            <IconButton size="small" aria-label="close" color="inherit" onClick={handleDevMessageClose}>*/}
        {/*                <CloseIcon fontSize="small"/>*/}
        {/*            </IconButton>*/}
        {/*        </>*/}
        {/*    }*/}
        {/*/>*/}
            <Box
                sx={{
                    bgcolor: 'error.main',
                    color: 'error.contrastText',
                    p: 2,
                    borderRadius: 1,
                    mb: 2,
                    position: 'relative',
                }}
            >
                <IconButton
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        color: 'white',
                    }}
                >
                    <CloseIcon/>
                </IconButton>
                <Typography variant="body1" align="center">
                    {error ? error.message : message}
                </Typography>
            </Box>
        </>
    );
}