import { FormattedMessage } from 'react-intl';

// assets
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
// ==============================|| MENU ITEMS - UPLOAD PAGE ||============================== //

const icons = {
    FormatListBulletedOutlinedIcon
};
const myContributionsPage = {
    id: 'reporting-module',
    title: <FormattedMessage id="My Contributions" />,
    icon: icons.FormatListBulletedOutlinedIcon,
    type: 'group',
    url: '/my-contributions'
};

export default myContributionsPage;
