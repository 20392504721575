import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { Link, Typography } from '@mui/material';

// project imports
import { DASHBOARD_PATH } from 'config';
// import Logo from 'assets/casabio-logo.png';
import Logo from '../../../assets/casabio-logo/Casabio CSI.png';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <Link component={RouterLink} to={DASHBOARD_PATH} aria-label="berry logo" underline="none">
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start'
            }}
        >
            <img
                src={Logo}
                alt="casabio logo"
                style={{
                    width: '40px'
                }}
            />

            <Typography gutterBottom variant="h2">
                    <span style={{ color: '#0CA9E2' }}>Casa</span>
                    <span style={{ color: '#5FBD4A' }}>bio</span>
            </Typography>
        </div>
    </Link>
);

export default LogoSection;
