import axiosServices from '../utils/axios';
import axios from 'axios'
import {environment} from "../config";

export const getPublicKey: (
) => Promise<any> = async (
): Promise<any> => {
    const response = await axiosServices.get('/api/keygen/public');
    return response.data;
}

export const loginUser: (
    data: object
) => Promise<any> = async (
    data: object
): Promise<any> => {
    const response = await axiosServices.post('/api/signin', data);
    return response.data;
}

export const logoutUser: (
    tokenID: string
) => Promise<any> = async (
    tokenID: string
): Promise<any> => {
    const response = await axiosServices.post(`/api/logout/${tokenID}`);
    return response.data;
}

export const registerUser: (
    userData: object
) => Promise<any> = async (
    userData: object
): Promise<any> => {
    const response = await axiosServices.post('/api/register', userData);
    return response.data;
}

export const forgotPassword: (
    userData: object ,
    token: string
) => Promise<any> = async (
    userData: object,
    token: string
): Promise<any> => {
    const response = await axiosServices.post(`/api/forgot-password/reset/${token}`, userData);
    return response.data;
};

export const checkUsernameExists: (
    username: string
) => Promise<any> = async (
    username: string
): Promise<any> => {
    return await axiosServices.get(`/api/register/checkUsername/${username}`);
}

export const checkEmailExists: (
    email: string
) => Promise<any> = async (
    email: string
): Promise<any> => {
    return await axiosServices.get(`/api/register/checkEmail/${email}`);
}

export const verifyEmailCode: (
    data: object
) => Promise<any> = async (
    data: object
): Promise<any> => {
    return await axiosServices.post('/api/register/verify-email',data);
}

export const forgotPasswordRequest: (
    data: object
) => Promise<any> = async (
    data: object
): Promise<any> => {
    return await axiosServices.post('/api/forgot-password/request',data);
}

export const getRefreshToken = (refreshToken) => {
    return new Promise((resolve, reject) => {
        axios.post(`${environment.API_URL}/api/refresh-token`, { token: refreshToken })
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            });
    });
};