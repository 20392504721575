import React from 'react';
import CasabioLogo from 'assets/casabio-logo/Casabio CSI.png';
import Button from '@mui/material/Button';
import './FooterLayout.css';
import {Box, Grid, InputAdornment, TextField, Typography} from "@mui/material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {sendContactUsEmail} from "api/contact-us";
import {useDispatch} from "store";
import {openSnackbar} from "store/slices/snackbar";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';

const FooterLayout = () => {
    const [subscriber, setSubscriber] = React.useState('');
    const dispatch = useDispatch();

    const aboutHandler = () => window.open(`/about`, '_blank');
    const contactHandler = () => window.open(`/contact-us`, '_blank');
    const linkedInHandler = () => {
        let link = document.createElement('a');
        link.href = 'https://www.linkedin.com/company/casabio/';
        link.target = '_blank';
        link.rel = 'noopener noreferrer';
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    const facebookHandler = () => {
        let link = document.createElement('a');
        link.href = 'https://www.facebook.com/profile.php?id=61560685865821';
        link.target = '_blank';
        link.rel = 'noopener noreferrer';
        document.body.appendChild(link);
        link.click();
        link.remove();
    };
    const subscribeHandler = async() => {
        if (validateEmail(subscriber)) {
            await submitSubscribeEmail(subscriber);
        } else {
            dispatch(
                openSnackbar({
                    open: true,
                    message: "Please enter a valid email address.",
                    variant: 'alert',
                    alert: {color: 'error'},
                    close: true
                })
            );
        }
    }

    const validateEmail = (email) => {
        const re = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
        return re.test(String(email).toLowerCase());
    }

    const submitSubscribeEmail = async (email) => {
        try {

            await sendContactUsEmail({
                name: 'No-name',
                email: email,
                title: 'Subscribe',
                category: 'Subscribe mail',
                message: "This user wants to subscribe to the mail list."
            });

            dispatch(
                openSnackbar({
                    open: true,
                    message: "Email subscribed successfully.",
                    variant: 'alert',
                    alert: {color: 'success'},
                    close: true
                })
            );
        } catch (e) {
            console.error(e);
            dispatch(
                openSnackbar({
                    open: true,
                    message: "Something went wrong, please try again later.",
                    variant: 'alert',
                    alert: {color: 'error'},
                    close: true
                })
            );
        }
    }

    return (
        <footer className="footer">
            <div className="footer-content">
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={4}>
                        <div className="logo">
                            <img src={CasabioLogo} alt="Casabio logo"/>
                            <span className="company-name"><span className="green-text">Casa</span><span
                                className="blue-text">bio</span></span>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <div className="subscribe-input">
                            <Box component="form"
                                 sx={{'& > :not(style)': {m: 1, width: '25ch', justifyContent: "center"}}}>
                                <Box>
                                    <Typography variant="h5" align="center">Subscribe to our newsletter.</Typography>
                                </Box>
                                <TextField
                                    type="email"
                                    onChange={e => setSubscriber(e.target.value)}
                                    placeholder="Email"
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Button
                                                    variant="contained"
                                                    style={{
                                                        backgroundColor: '#5fbd4a',
                                                        minWidth: '30px',
                                                        padding: '6px',
                                                    }}
                                                    onClick={subscribeHandler}>

                                                    <ChevronRightIcon/>

                                                </Button>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>

                        </div>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <nav className="footer-navigation">
                            <p className="footer-nav-item" onClick={aboutHandler}>About Us</p>
                            <p className="footer-nav-item" onClick={contactHandler}>Contact</p>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                <p className="footer-nav-item" onClick={linkedInHandler}><LinkedInIcon sx={{fontSize: '2.5rem'}}/></p>
                                <p className="footer-nav-item" onClick={facebookHandler}><FacebookIcon sx={{fontSize: '2.5rem'}}/></p>
                            </div>

                        </nav>
                    </Grid>

                </Grid>

            </div>
            <div className="footer-base">
            <p className="copyright">© 2024 Casabio - All Rights Reserved</p>
            </div>
        </footer>
    );
};

export default FooterLayout;