// import axios from "axios";
import axios from 'utils/axios';

// Function to get the user profile
export const getUserProfile = async () => {
        // send a GET request to /api/profile
        const response = await axios.get('/api/profile');
        return response.data; // Return the response data directly
};

export const editUserProfile = async (data) => {
        // send a GET request to /api/profile
        return await axios.put('/api/profile', data);
};

export const getProfileFromID = async (id) => {
    const response = await axios.get(`/api/profile/fromID`, {
        params: {
            userID: id
        }
    });

    return response.data;
}