import LAYOUT_CONST from 'constant';

export const DASHBOARD_PATH = '/';
export const HORIZONTAL_MAX_ITEM = 7;

export const ThemeMode = {
    LIGHT: 'light'
};

const config = {
    layout: LAYOUT_CONST.VERTICAL_LAYOUT, // vertical, horizontal
    drawerType: LAYOUT_CONST.DEFAULT_DRAWER, // default, mini-drawer
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 8,
    outlinedFilled: true,
    navType: 'light', // light, dark
    presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
    locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    rtlLayout: false,
    container: false,
    mode: ThemeMode.LIGHT,
};
export default config;

//     All .env variables
export const environment = {
    API_URL: process.env.REACT_APP_API_URL || 'https://casabio-backend-casabio-prod.k8s-a1.casabio.org/',
    BASE_NAME: process.env.REACT_APP_BASE_NAME || null,
    MAX_FILE_SIZE: process.env.REACT_APP_MAX_FILE_SIZE || 20.0,
    MERCHANT_ID: process.env.REACT_APP_MERCHANT_ID || 10034588,
    MERCHANT_KEY: process.env.REACT_APP_MERCHANT_KEY || 'q5o586u55numb',
    RETURN_URL: process.env.REACT_APP_RETURN_URL || 'https://casabio.org/donations',
    CANCEL_URL: process.env.REACT_APP_CANCEL_URL || 'https://casabio.org/donations/donate',
    NOTIFY_URL: process.env.REACT_APP_NOTIFY_URL || 'https://casabio-backend-casabio-prod.casabio.org:443/payments/donations/payfast-notify',
    ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT || 'PROD',
};